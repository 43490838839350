<template>
  <default-layout :searchFunction="loadListing" searchParamString="q">
    <v-container fluid tag="section">
      <page-loading :show="loading || loadingForm || listingLoading" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <agent-detail-form
        :detail="detail"
        :permissions="permissions"
        :allowedActions="allowedActions"
        style="width: 100%"
      ></agent-detail-form>
      <listing-list
        :items="listings"
        :meta="listingMeta"
        :permissions="listingPermissions"
        :agent-uuid="detail.uuid"
        :totalVisit="totalVisit"
        :totalImpression="totalImpression"
        :totalWaLead="totalWaLead"
        :totalContact="totalContact"
        @changePage="changeListingPage"
        @changeStatusFilter="changeStatusFilter"
        v-if="
          detail &&
          detail.membership &&
          viewListingPermissions &&
          viewListingPermissions.includes('VIEW')
        "
      ></listing-list>
    </v-container>
    <modal-agent></modal-agent>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const AgentDetailForm = () => import('@/components/user-management/agent-member/agent-detail-form');
const ListingList = () => import('@/components/data-entry/listing/list');
const ModalAgent = () => import('@/components/modals/modal-agent');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    AgentDetailForm,
    ListingList,
    ModalAgent,
  },
  data() {
    return {
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.agentMember.loading,
      loadingForm: (state) => state.agentMember.form.loading,
      permissions: (state) => state.agentMember.permissions,
      allowedActions: (state) => state.agentMember.allowedActions,
      detail: (state) => state.agentMember.detail,
      viewListingPermissions: (state) => state.agentMember.viewListingPermissions,
      listingLoading: (state) => state.listing.loading,
      listings: (state) => state.listing.listings,
      listingMeta: (state) => state.listing.meta,
      listingPermissions: (state) => state.listing.permissions,
      totalVisit: (state) => state.listing.totalVisit,
      totalImpression: (state) => state.listing.totalImpression,
      totalWaLead: (state) => state.listing.totalWaLead,
      totalContact: (state) => state.listing.totalContact,
    }),
  },
  async mounted() {
    await this.refreshAction();
  },
  methods: {
    async changeStatusFilter(val) {
      let query = this.$route.query;
      query.status = val;
      await this.loadListing(query);
    },
    async refreshAction() {
      await this.loadDetail();
      await this.changeListingPage(1);
    },
    async loadDetail() {
      try {
        this.$store.commit('agentMember/RESET_DEFAULT');
        const response = await this.$store.dispatch(
          'agentMember/getDetailInitData',
          this.$route.params.uuid,
        );
        console.log('DETAIL AGEN: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      }
    },
    async changeListingPage(page) {
      let query = this.$route.query;
      query.page = page ? page : 1;
      query.url = 'data-entry/listings';
      await this.loadListing(query);
    },
    async loadListing(query) {
      query.agent_uuid = this.$route.params.uuid;
      this.$store.commit('listing/RESET_DEFAULT');
      if (
        this.detail &&
        this.detail.membership &&
        this.viewListingPermissions &&
        this.viewListingPermissions.includes('VIEW')
      ) {
        try {
          query.agent_uuid = this.$route.params.uuid;
          query.url = 'data-entry/listings';
          const response = await this.$store.dispatch('listing/getInitData', query);
          console.log('GET LISTING DATA: ', response);
        } catch (e) {
          this.showAlert = true;
          this.messageAlert = e;
        }
      }
    },
  },
};
</script>
